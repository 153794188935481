* {box-sizing: border-box;}

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ebebeb;
}

div,span,p,h1,h2,h3,h4,button {
  font-family: 'Montserrat', sans-serif;
}

#root {
  display: flex;
  justify-content: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
